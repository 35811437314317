import React, { Fragment } from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import GetPrefix from "../../helpers/GetPrefix"
import { GatsbyImage } from "gatsby-plugin-image"
import GetMicroCopy from "../../shared/getMicroCopy"
import { useMicroCopyFi } from "../../hooks/microCopy"
import { useMicroCopySv } from "../../hooks/microCopy/sv"
import BackLink from "../../components/backLink"
import Seo from "../../components/seo"
import BasicPageSidebar from "../../components/basicPageSidebar"
import BasicPageReferences from "../../components/basicPageReferences"
import LayoutController from "../../components/layoutController"


const ExpoSubPage = ({ data }) => {
  const pageContent = R.path(["contentfulContent"], data)
  const parentContent = R.path(["parentContent"], data)
  const language =  R.path(["node_locale"], parentContent)
  const microCopyTexts = (language === '/sv' || language === 'sv') ? useMicroCopySv : useMicroCopyFi
  // UI texts
  const labelHomePage = GetMicroCopy(microCopyTexts, "yleinenEtusivu")
  // end UI texts
  const parentImage = R.path(["mainImage", "gatsbyImageData"], parentContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const parentTitle = R.path(["title"], parentContent)
  const parentSlug = R.path(["slug"], parentContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)
  const title = R.path(["title"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], parentContent)
  // Palvelutori settings
  const serviceCentre = R.path(["serviceCentre"], pageContent) 

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={`${title} | ${parentTitle}`}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <section className="max-w-6xl mx-auto mt-10 mb-20" id={title}>
        <div className="px-5">
          <div className="mb-8">
            <BackLink url={GetPrefix(language, parentSlug)} label={parentTitle} />
          </div>
          {serviceCentre && 
            <div className="text-center">
              <h1>{title}</h1>
            </div>
          }
          {mainImage ? (
            <GatsbyImage
              className="aspect-[2/1]"
              image={mainImage}
              alt={parentTitle}
            />
          ) : (
            <GatsbyImage
              className="aspect-[2/1]"
              image={parentImage}
              alt={parentTitle}
            />
          )}
        </div>
        <div
          className={`${serviceCentre ? "max-w-3xl m-auto mt-12 px-5" : "md:grid md:grid-cols-3 gap-12 mt-6 md:mt-12 px-5"} `}
        >
          <div className="col-start-2 col-end-4">
            {!serviceCentre && 
            <h1>{title}</h1>
            }
            <BasicPageReferences pageContent={pageContent} language={language}/>
            
          </div>
          {!serviceCentre && 
          <div className="md:col-start-1 md:row-start-1">
            <nav className="px-5">
              <ol className="flex flex-row list-none text-sm m-0 p-0 text-black font-sans-medium">
                <li>
                  <Link className="text-text hover:underline" to={GetPrefix(language, "")}>
                    {labelHomePage}
                    <span aria-hidden="true" className="px-1">
                      ·
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-text hover:underline"
                    to={GetPrefix(language, `${parentSlug}/`)}
                  >
                    {parentTitle}
                  </Link>
                </li>
              </ol>
            </nav>
            <BasicPageSidebar pageContent={parentContent} language={language} />
          </div>
          }
        </div>
      </section>
    </LayoutController>
  )
}

export default ExpoSubPage

export const pageQuery = graphql`
  query expoSubPage($uniqueEntryTitle: String, $parentSlug: String) {
    parentContent: contentfulMessu(slug: { eq: $parentSlug }) {
      id
      slug
      node_locale
      title
      mainImage {
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      contentTextShort
      subpages {
        node_locale
        ... on Node {
          ... on ContentfulPerusalasivu {
            __typename
            id
            slug
            title
            uniqueEntryTitle
            serviceCentre
            hideSubpage
          }
        }
      }
    }
    contentfulContent: contentfulPerusalasivu(
      uniqueEntryTitle: { eq: $uniqueEntryTitle },
    ) {
      id
      node_locale
      slug
      title
      mainImage {
        gatsbyImageData(layout: CONSTRAINED, width: 1200)
      }
      contentTextShort
      serviceCentre
      hideSubpage
      contentArea {
        ... on Node {
          ... on ContentfulHaitarielementti {
            node_locale
            __typename
            id
            alaosiot {
              title
              childContentfulHaitarialaosioContentTextTextNode {
                contentText
              }
            }
          }
          ... on ContentfulLinkkilista {
            __typename
            id
            node_locale
            name
            links {
              ... on Node {
                ... on ContentfulUlkoinenLinkki {
                  __typename
                  id
                  url
                  urlText
                }
              }
            }
          }
          ... on ContentfulPerusalasivunTekstikappale {
            __typename
            id
            node_locale
            title
            contentText {
              contentText
            }
            richText {
              raw
              references {
                ... on Node {
                  ... on ContentfulAsset {
                    id
                    contentful_id
                    __typename
                    title
                    description
                    gatsbyImageData(width: 800)
                    file {
                      url
                      fileName
                    }
                  }
                }
                ... on Node {
                  ... on ContentfulHenkilo {
                    node_locale
                    contentful_id
                    id
                    __typename
                    name
                    contentText {
                      contentText
                    }
                    image {
                      gatsbyImageData(width: 300)
                    }
                  }
                }
                ... on Node {
                  ... on ContentfulNosto {
                    id
                    node_locale
                    contentful_id
                    __typename
                    title
                    contentText {
                      contentText
                    }
                    image {
                      gatsbyImageData(width: 500)
                    }
                    buttonText
                    buttonLink
                    backgroundColor
                    reverseImage
                  }
                }
                ... on Node {
                  ... on ContentfulPainike {
                    id
                    node_locale
                    contentful_id
                    __typename
                    ctaText
                    ctaTarget
                    ctaColor
                  }
                }
                ... on Node {
                  ... on ContentfulNostoruudukko {
                    contentful_id
                    __typename
                    id
                    node_locale
                    teasers {
                      ... on Node {
                        ... on ContentfulNosto {
                          id
                          node_locale
                          contentful_id
                          __typename
                          title
                          contentText {
                            contentText
                          }
                          image {
                            gatsbyImageData(width: 500)
                          }
                          buttonText
                          buttonLink
                          backgroundColor
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulPerusalasivunosto {
            __typename
            id
            node_locale
            color
            contentText {
              contentText
            }
          }
        }
      }
    }
  }
`
